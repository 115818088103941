// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
//@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.jq-error {
  color: #dd4b39;
}

tr.bg-red {
  .editable-click, a.editable-click, a.editable-click:hover {
    text-decoration: none;
    border-bottom: dashed 1px #fff;
  }

  h3 {
    color: #333333;
  }

  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.rw-form {
  border-left: 2px solid black;
}

.select2 {
  width: 100% !important;
}

.input-group {
   width: 100%;
}

.input-group-addon {
  cursor: pointer;
  width: 37px;
  height: 34px;
}

.tiles {
  .col-lg-3 {
    width: 20%;
  }
}

.details-control {
  i {
    cursor: pointer;
  }
}

#filter-datetimerange {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.m-b-0 {
  margin-bottom: 0;
}

.no-ml {
  margin-left: 0 !important;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #C1C1C1;
}

dl {
  margin-bottom: 0;
}

.dl-horizontal dd {
  margin-left: 50px;
}

.dl-horizontal dt {
  width: 35px;
}
.box.box-solid {
  border: 1px solid #C1C1C1;
}

.user-panel {
  min-height: 53px;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: 100px;
}

.status-td {
  min-width: 84px;
}

.select2-container--open {
  z-index: 9990;
}

.driver-td {
  .editable-input {
    min-width: 150px;
  }
}

.select2-selection__clear {
  margin-left: 10px;
}

a.status.label.editable.editable-click {
  float: right;
}

td.id-td {
  white-space: nowrap;
}

@mixin padding($position, $value) {
  padding-#{$position}: $value;
}

.p-l-5 {
  @include padding('left', 5px);
}

.p-r-5 {
  @include padding('right', 5px);
}

.width-100 {
  width: 100%;
}

td.details-control {
  cursor: pointer;
}

.form-control {
  padding: 6px 6px;
}

.min-width--80 {
    min-width: 80px;
}
.daterangepicker {
    td {
      &.in-range {
        background-color: transparent;
      }
      &.active, &:hover {
        background-color: #357ebd;
      }
  }
}

.bage {
  position: absolute;
  top: -10px;
  right: 10px;
  text-align: center;
  font-size: 10px;
  padding: 2px 3px;
  line-height: .9;
}

.info-box {
  & a {
    color: #fff;
  }
}

//.table-condensed thead tr:nth-child(2),
//.table-condensed tbody {
//  display: none;
//}

//.daterangepicker select.yearselect {
//  width: 55%;
//}
//.jquery-alert {
//  display: none;
//}
